<script>
import { apiMixins } from "../../mixins/api.js";

export default {
  props: {
    item: {
      type: Object,
      required: false,
    },
    availableRetentionSchedules: {
      type: Array,
      required: true
    },
    currentRetentionSchedule: {
      type: String,
      required: false,
    }
  },
  mixins: [apiMixins],
  emits: ["retention-schedule-changed"],
  data() {
    return {
      workingRetentionSchedule: null,
      selectedRetentionSchedule: null,
      workingRegion: "default",
      workingRetentionScheduleId: null,
      parsedItemSchedule: this.item && this.item.retSchedule && this.item.retSchedule.replace("#", ""),
    };
  },
  computed: {
    filteredRetentionSchedules() {
      if (this.workingRegion) {
        return this.availableRetentionSchedules.filter(r => r.region === this.workingRegion);
      }
      return this.availableRetentionSchedules;
    },
  },
  methods: {
    onRegionChangedHandler() {
      this.workingRegion = null;
    },
    onRetentionScheduleChanged(event) {
      this.selectedRetentionSchedule = this.availableRetentionSchedules.find(r => r.id === event.value);
      this.$emit("retention-schedule-changed", this.selectedRetentionSchedule);
    },
    isCurrentItemRetentionScheduleEmpty() {
      return !this.currentRetentionSchedule;
    }
  },
  mounted() {
    if (!this.isCurrentItemRetentionScheduleEmpty()) {
      this.workingRetentionSchedule = this.availableRetentionSchedules.find(r => r.id === this.currentRetentionSchedule);
      this.workingRegion = this.workingRetentionSchedule.region;
      this.workingRetentionScheduleId = this.workingRetentionSchedule.id;
    }
  },
};
</script>

<template>
  <div>
    <div class="retention-block">
      <label for="select-retention-schedule-region" class="retention-label"
        >Region:</label
      >
      <m-select
        id="select-retention-schedule-region"
        outlined
        v-model="workingRegion"
        class="retention-select"
      >
        <option value="default">Default Region</option>
        <option value="svk">SVK Region</option>
      </m-select>
    </div>
    <div class="retention-block">
      <label for="select-retention-schedule" class="retention-label"
        >{{ $t("retention-schedule-picker.available-schedules-label") }}</label
      >
      <m-select
        id="select-retention-schedule"
        outlined
        v-model="workingRetentionScheduleId"
        class="retention-select"
        @change="onRetentionScheduleChanged"
      >
        <option value="">{{ $t("retention-schedule-picker.default-schedule-option") }}</option>
        <option
          v-for="schedule in filteredRetentionSchedules"
          :key="schedule.id"
          :value="schedule.id"
          :selected="schedule.id === workingRetentionScheduleId"
          >{{ schedule.id }}: - {{ schedule.title }} ({{
            schedule.period
          }})</option
        >
      </m-select>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.retention-select {
  width:100%;
  margin-top:0.5rem;
  margin-left: 0.5rem;
}

.retention-label {
  font-weight:bold;
}

.retention-block {
  margin-bottom:1rem;
}

</style>
